import {concat} from 'lodash';
import {LoanApplicationStepsRoutePaths, RoutePaths} from '../../../../routes/routes.paths';
import {LoanStep} from '../../../../shared/model/loan-step.model';
import {Phase, PhaseType} from '../../../../shared/model/phase.model';
import {LoanSteps} from '../../../../utils/loan-steps-base-class';
import {buildApplicationStepPath, getPredefinedApplicationSteps} from '../../../../utils/loan-steps-utils';
import {getCustomPhases} from '../../../../utils/phases-utils';
import {PREDEFINED_FIRST_APPLICATION_STEPS, PREDEFINED_LAST_APPLICATION_STEPS} from './predefined-steps';
import {Application, LoanApplicationSimulation} from '../loan-application.model';
import {
  ADDITIONAL_LOAN_APPLICATION_PATH,
  CONSOLIDATE_LOAN_APPLICATION_PATH,
  RELOAN_APPLICATION_PATH
} from '../../../dashboard/routes/dashboard-routes';
import {LoanCreationType} from '../../../../shared/model/creation-type.model';

export class LoanApplicationSteps extends LoanSteps {

  constructor(processId: number | string,
              applicationId: number | string,
              phases: Phase[] = [],
              application: Application | undefined) {

    const loanCreationType = getCreationType(applicationId, phases, application);

    const customPhases = getCustomPhases(phases)
      .filter(phase => filterCustomPhasesByIdOrCreationType(phase, applicationId, application, loanCreationType));
    const steps = [
      ...getPredefinedApplicationSteps(processId, applicationId, PREDEFINED_FIRST_APPLICATION_STEPS, phases),
      ...customPhases.map(phase => ({
        phaseId: phase.id,
        label: phase.name,
        path: buildApplicationStepPath(`${RoutePaths.CUSTOM}/${phase.id}`, processId, applicationId),
        isCustomStep: true
      })) as LoanStep[],
      ...getPredefinedApplicationSteps(processId, applicationId, PREDEFINED_LAST_APPLICATION_STEPS, phases)
    ];

    steps.forEach((value, index) => value.orderNo = `${index + 1}`);

    super(addCustomerDataSubSteps(steps));
  }
}

const filterCustomPhasesByIdOrCreationType = (phase: Phase,
                                              applicationId: number | string,
                                              application: Application | undefined,
                                              loanCreationType: string | undefined): boolean => {
  const isCustomPhase = phase.type === PhaseType.CUSTOM_PHASE;
  if (!isNaN(Number(applicationId))) {
    return isCustomPhase && (application?.customPhaseIds?.some(phaseId => phaseId === phase.id) || false);
  }

  return isCustomPhase && (phase.creationTypes?.some(creationType => creationType === loanCreationType) || false);
};

const getCreationType = (applicationId: number | string,
                         phases: Phase[],
                         application: Application | undefined): string | undefined => {
  if (isNaN(Number(applicationId))) {
    const creationTypePath = String(applicationId);
    if (CONSOLIDATE_LOAN_APPLICATION_PATH.includes(creationTypePath)) {
      return LoanCreationType.CONSOLIDATION.toString();
    }
    if (ADDITIONAL_LOAN_APPLICATION_PATH.includes(creationTypePath)) {
      return LoanCreationType.ADDITIONAL_LOAN.toString();
    }
    if (RELOAN_APPLICATION_PATH.includes(creationTypePath)) {
      return LoanCreationType.RELOAN.toString();
    }
    return LoanCreationType.NEW_LOAN.toString();
  }

  const loanApplicationDataPhase = getPhaseByType(phases, PhaseType.LOAN_APPLICATION_DATA);
  const loanApplicationDataSimulation = getSimulationFromPhase(application?.simulations || [],
    loanApplicationDataPhase?.id);

  // Check if there's already has loan application data phase since the final creation type will be based from there
  // if there's no data yet then get the initial creation type from the
  if (loanApplicationDataSimulation) {
    return loanApplicationDataSimulation.input.creationType;
  }

  const calculatorPhase = getPhaseByType(phases, PhaseType.CALCULATOR);
  const calculatorSimulation = getSimulationFromPhase(application?.simulations || [], calculatorPhase?.id);

  return calculatorSimulation?.input.creationType;
};

const getPhaseByType = (phases: Phase[], type: PhaseType): Phase | undefined =>
  phases.find(phase => phase.type === type);

const getSimulationFromPhase = (simulations: LoanApplicationSimulation[],
                                phaseId: number | undefined): LoanApplicationSimulation | undefined =>
  simulations.find(simulation => simulation.phaseId === phaseId);

const addCustomerDataSubSteps = (steps: LoanStep[]): LoanStep[] => {
  const customerDataIndex = steps.findIndex(step => step.type === 'IndividualCustomerProfilingPhaseView');
  const customerDataStep = steps[customerDataIndex];

  const borrowerStep = {
    ...customerDataStep,
    isSubStep: true,
    label: 'LOAN_STEPS_LABELS.BORROWER',
    path: customerDataStep.path + LoanApplicationStepsRoutePaths.BORROWER,
    orderNo: `${customerDataStep.orderNo}.1`,
    ...customerDataStep.redirectTo
      ? {redirectTo: customerDataStep.redirectTo + LoanApplicationStepsRoutePaths.BORROWER}
      : {},
    phaseId: undefined
  };

  const coBorrowerStep = {
    ...customerDataStep,
    isSubStep: true,
    label: 'LOAN_STEPS_LABELS.CO-BORROWER',
    path: customerDataStep.path + LoanApplicationStepsRoutePaths.CO_BORROWERS,
    orderNo: `${customerDataStep.orderNo}.2`,
    ...customerDataStep.redirectTo
      ? {redirectTo: customerDataStep.redirectTo + LoanApplicationStepsRoutePaths.CO_BORROWERS}
      : {},
    phaseId: undefined
  };

  const coMakerStep = {
    ...customerDataStep,
    isSubStep: true,
    label: 'LOAN_STEPS_LABELS.CO-MAKER',
    path: customerDataStep.path + LoanApplicationStepsRoutePaths.CO_MAKERS,
    orderNo: `${customerDataStep.orderNo}.3`,
    ...customerDataStep.redirectTo
      ? {redirectTo: customerDataStep.redirectTo + LoanApplicationStepsRoutePaths.CO_MAKERS}
      : {},
    phaseId: undefined
  };

  const stepsLeftSide = steps.slice(0, customerDataIndex + 1);
  const stepsRightSide = steps.slice(customerDataIndex + 1);

  return concat(stepsLeftSide, [borrowerStep, coBorrowerStep, coMakerStep], stepsRightSide);
};
