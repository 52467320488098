import {NxFormikCashInput, NxFormikCheckbox, NxFormikNumberInput} from '@nextbank/ui-components';
import React, {ReactElement, useContext} from 'react';
import {SimulatedFee} from '../../../../../../../../shared/model/loan-simulation.model';
import {CashHelper} from '../../../../../../../../utils/cash-helper';
import {StringHelper} from '../../../../../../../../utils/string-helper';
import {TransHelper} from '../../../../../../../../utils/trans-helper';
import {StepContext} from '../../../loan-application-step/LoanApplicationStep';
import styles from './FeesOverrideTableBody.module.scss';
import BigNumber from 'bignumber.js';

interface Props {
  fees: SimulatedFee[];
  formFees?: SimulatedFee[];
}

export const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.LOAN_APPLICATION_DATA.OVERRIDE_FEES');

export const FeesOverrideTableBody = ({fees, formFees}: Props): ReactElement => {

  const {isStepReadonly} = useContext(StepContext);

  const computeDifference = (fixedAmount: number, originalFixedAmount): number => BigNumber(fixedAmount)
    .minus(originalFixedAmount)
    .toNumber();

  return (
    <>
      {
        fees.map((fee, index) => {
          const formIndex = formFees === undefined
            ? index
            : formFees.findIndex(formFee => formFee.feeSourceCode === fee.feeSourceCode);
          const getName = (key: keyof SimulatedFee): string => `simulation.input.simulatedFees[${formIndex}].${key}`;
          return (
            <>
              <div>{fee.feeName}</div>
              <div>
                {
                  fee.isFixed
                    ? <NxFormikCashInput label={<PrefixTrans>AMOUNT</PrefixTrans>}
                                         name={getName('originalFixedAmount')}
                                         disabled={true} />
                    : <NxFormikNumberInput label={<PrefixTrans>PERCENTAGE</PrefixTrans>}
                                           name={getName('originalPercentage')}
                                           endAdornment={'%'}
                                           showArrows={false}
                                           disabled={true} />
                }
              </div>
              <div>
                {
                  fee.isFixed
                    ? <NxFormikCashInput label={<PrefixTrans>AMOUNT</PrefixTrans>}
                                         name={getName('fixedAmount')}
                                         disabled={isStepReadonly} />
                    : <NxFormikNumberInput disabled={isStepReadonly}
                                           name={getName('percentage')}
                                           endAdornment={'%'}
                                           showArrows={false}
                                           label={<PrefixTrans>PERCENTAGE</PrefixTrans>} />
                }
              </div>
              <div className={styles.difference}>
                {
                  computeDifference(fee.fixedAmount, fee.originalFixedAmount) != 0 && computeDifference(fee.fixedAmount,
                    fee.originalFixedAmount) != 0
                    ? fee.isFixed
                      ? CashHelper.addCurrencyMask(computeDifference(fee.fixedAmount, fee.originalFixedAmount))
                      : StringHelper.addPercentage(computeDifference(fee.percentage, fee.originalPercentage))
                    : '-'
                }
              </div>
              <div>
                <NxFormikCheckbox className={styles.alreadyPaid}
                                  name={getName('alreadyPaid')}
                                  disabled={isStepReadonly} />
              </div>
            </>
          );
        })
      }
    </>
  );
};
